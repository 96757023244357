/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const SrLPHero = {
  title: "Best-in-class restaurant POS system",
  subtext:
    "Adapt and grow your restaurant with an end-to-end solution built by restaurateurs.",
  heroImg: "sr-lp.png",
  imageClassName: "",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-sf",
  },
  fatTitle: "Restaurant",
};

export const SrLPWhiteGlove = {
  mainTitle: "Personalized support that’s working when you are",
  title: "White-glove service & support",
  content:
    "Get the local in-person support your restaurant deserves, along with around-the-clock technical support included at no extra cost.",
  contentList: [
    "Premium in-person hardware installation",
    "Face-to-face staff training from SpotOn specialists",
    "Ongoing virtual training for staff and management",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-sf",
  },
};

export const SrLPLargeFeatures = {
  sectionTitle: "Powerful tools. Customized to your needs",
  featureBlocks: [
    {
      blockTitle: "Built to run and grow your restaurant",
      blockImg: "sr-lp1.png",
      blockSubTitle: "",
      blockList: [
        "Lightning-fast front-of-house",
        "Powerful back-of-house",
        "Online ordering & delivery",
        "Handheld POS & mobile ordering",
        "Seamless integrations with key restaurant tech",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-sf",
      },
    },
    {
      blockTitle: "Elite guest data & reporting",
      blockImg: "sr-lp2.png",
      blockList: [
        "90+ intuitive reports",
        "Enterprise reporting",
        "Manager alerts & subscriptions",
        "Reporting app to monitor data anywhere, anytime",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-sf",
      },
    },
    {
      blockTitle: "Custom POS build",
      blockImg: "sr-lp3.png",
      blockList: [
        "Dine-in & online menus",
        "Table layouts",
        "Staff profiles",
        "Fully scalable hardware configurations",
        "Loyalty, Marketing, and Review Management included",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/demo-sf",
      },
    },
  ],
};

export const SrLPTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const SrLPCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/demo-sf",
  },
};

export const SrTemplate = [
  {
    content: " Intuitive front-of-house",
    desc: "Streamline operations, accelerate growth, and provide guests with a five-star experience, in-house and online.",
    img: "book-open.png",
  },
  {
    content: "Cloud-based back-of-house",
    desc: "Manage employee schedules, menus, table layouts, pricing events, and more—from anywhere.",
    img: "urgent.png",
  },
  {
    content: "Online ordering",
    desc: "Offer commission-free online ordering directly to your customers for take-out, delivery, and dine-in.",
    img: "award.png",
  },
  {
    content: "Handheld POS",
    desc: "Enhance your guest experience and turn more tables with easy-to-use handhelds, part of our fully scalable hardware options.",
    img: "calc.png",
  },
  {
    content: "Elite guest data & reporting",
    desc: "Make smarter decisions with 90+ real-time reports, automated manager alerts and subscriptions, and enterprise reporting.",
    img: "pie-chart.png",
  },
  {
    content: "Seamless integration",
    desc: "Combine SpotOn with the software you already know and use, or integrate SpotOn’s intuitive software solutions for one easy-to-use system.",
    img: "git-merge.png",
  },
];
